import React, { useEffect } from "react"
import { navigate } from "gatsby"
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import BlueLabel from "../images/logo-blue-small.svg"
import SilverLabel from "../images/logo-silver-small.svg"
import GoldLabel from "../images/logo-gold-small.svg"
import DLGoldLabelPNG from "../images/download-logo-gold.png"
import DLGoldLabelJPG from "../images/download-logo-gold.jpg"
import DLBlueLabelPNG from "../images/download-logo-blue.png"
import DLBlueLabelJPG from "../images/download-logo-blue.jpg"
import DLSilverLabelPNG from "../images/download-logo-silver.png"
import DLSilverLabelJPG from "../images/download-logo-silver.jpg"
import GradientImage from "../images/gradient-big.png"

const LabelDownload = () => {
  useEffect(() => {
    if (process.env.GATSBY_VARIANT === "Europe") {
      navigate("/en/label-download")
    }
  }, [])

  return (
    <React.Fragment>
      <PageTitle title={"Label-Download"} />
      <Layout>
        <div className="label-download-gradientimg--wrapper">
          <img src={GradientImage} alt="Gradient" title="Gradient" />
        </div>
        <div className="page__wrap">
          <div className="breadcrumbs">
            <ul>
              <li>
                <a onClick={() => navigate("/")}>Home</a>
              </li>
              <li>
                <a>Label-Download</a>
              </li>
            </ul>
          </div>
          <div className="label-download">
            <h1>Label-Download</h1>
            <div className="text">
              {process.env.GATSBY_VARIANT === "Austria" ? (
                <p>
                  Hier können sie das Label nach Erhalt der
                  Qualifizierungsbestätigung zu ihrer weiteren Verwendung gemäß
                  der <a href="#">Label-AGBs</a> in hoher Auflösung herunterladen.
                </p>
              ) : (
                <p>
                  After receiving the confirmation of qualification, you can download the 
                  respective label here in high resolution for further use in accordance 
                  with the <a href="#">label terms and conditions</a>.
                </p>
              )}
            </div>
            <div className="label-download__block">
              <div className="label-download__block--box label-download__main">
                <div className="image">
                  <img
                    src={BlueLabel}
                    className="mw-100"
                    alt="Cyber Trust Austria Standard Label"
                    title="Cyber Trust Austria Standard Label"
                  />
                </div>
                <ul>
                  <li>
                    <a>
                      <span className="icon">
                        <svg
                          viewBox="0 0 28 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M1.75 25.3735V2.62355C1.75 2.14028 2.14173 1.74855 2.625 1.74855H15.75V5.24855C15.75 6.21504 16.5335 6.99855 17.5 6.99855H21V9.62355H22.75V6.12355C22.7514 5.89096 22.66 5.6674 22.4962 5.5023L17.2462 0.252299C17.0811 0.08851 16.8576 -0.00276338 16.625 -0.00145088H2.625C1.17523 -0.00145088 0 1.17384 0 2.62355V25.3735C0 26.8233 1.17523 27.9985 2.625 27.9985H8.75V26.2485H2.625C2.14178 26.2485 1.75 25.8568 1.75 25.3735Z"></path>
                          <path d="M26.25 17.5H24.5V19.25H26.25V21H22.75V15.75H28C28 14.7835 27.2165 14 26.25 14H22.75C21.7835 14 21 14.7835 21 15.75V21C21 21.9665 21.7835 22.75 22.75 22.75H26.25C27.2165 22.75 28 21.9665 28 21V19.25C28 18.2835 27.2165 17.5 26.25 17.5Z"></path>
                          <path d="M10.5 14H7C6.51673 14 6.125 14.3917 6.125 14.875V22.75H7.875V20.125H10.5C11.4665 20.125 12.25 19.3415 12.25 18.375V15.75C12.25 14.7835 11.4665 14 10.5 14ZM10.5 18.375H7.875V15.75H10.5V18.375Z"></path>
                          <path d="M18.375 19.25L14.7 14.35C14.41 13.9635 13.8616 13.8851 13.475 14.175C13.2547 14.3403 13.125 14.5996 13.125 14.875V22.75H14.875V17.5L18.55 22.4C18.7153 22.6204 18.9746 22.75 19.25 22.75C19.3451 22.7508 19.4397 22.736 19.53 22.7063C19.8864 22.5859 20.126 22.2512 20.125 21.875V14H18.375V19.25Z"></path>
                        </svg>
                      </span>
                      <span className="info">
                        <a href={DLBlueLabelPNG}>Standard Label (PNG)</a>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a>
                      <span className="icon">
                        <svg
                          viewBox="0 0 28 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M1.75 25.375V2.62501C1.75 2.14174 2.14173 1.75001 2.625 1.75001H15.75V5.25001C15.75 6.21651 16.5335 7.00001 17.5 7.00001H21V9.62501H22.75V6.12501C22.7514 5.89243 22.66 5.66887 22.4962 5.50376L17.2462 0.253764C17.0811 0.0899749 16.8576 -0.00129854 16.625 1.39617e-05H2.625C1.17523 1.39617e-05 0 1.1753 0 2.62501V25.375C0 26.8248 1.17523 28 2.625 28H8.75V26.25H2.625C2.14178 26.25 1.75 25.8583 1.75 25.375Z"></path>
                          <path d="M26.25 17.5H24.5V19.25H26.25V21H22.75V15.75H28C28 14.7835 27.2165 14 26.25 14H22.75C21.7835 14 21 14.7835 21 15.75V21C21 21.9665 21.7835 22.75 22.75 22.75H26.25C27.2165 22.75 28 21.9665 28 21V19.25C28 18.2835 27.2165 17.5 26.25 17.5Z"></path>
                          <path d="M18.375 14H14.875C14.3917 14 14 14.3917 14 14.875V22.75H15.75V20.125H18.375C19.3415 20.125 20.125 19.3415 20.125 18.375V15.75C20.125 14.7835 19.3415 14 18.375 14ZM18.375 18.375H15.75V15.75H18.375V18.375Z"></path>
                          <path d="M11.375 21H7.875V22.75H11.375C12.3415 22.75 13.125 21.9665 13.125 21V14H11.375V21Z"></path>
                        </svg>
                      </span>
                      <span className="info">
                        <a href={DLBlueLabelJPG}>Standard Label (JPG)</a>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="label-download__block--box label-download__silver">
                <div className="image">
                  <img
                    src={SilverLabel}
                    className="mw-100"
                    alt="Cyber Trust Austria Silver Label"
                    title="Cyber Trust Austria Silver Label"
                  />
                </div>
                <ul>
                  <li>
                    <a>
                      <span className="icon">
                        <svg
                          viewBox="0 0 28 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M1.75 25.3735V2.62355C1.75 2.14028 2.14173 1.74855 2.625 1.74855H15.75V5.24855C15.75 6.21504 16.5335 6.99855 17.5 6.99855H21V9.62355H22.75V6.12355C22.7514 5.89096 22.66 5.6674 22.4962 5.5023L17.2462 0.252299C17.0811 0.08851 16.8576 -0.00276338 16.625 -0.00145088H2.625C1.17523 -0.00145088 0 1.17384 0 2.62355V25.3735C0 26.8233 1.17523 27.9985 2.625 27.9985H8.75V26.2485H2.625C2.14178 26.2485 1.75 25.8568 1.75 25.3735Z"></path>
                          <path d="M26.25 17.5H24.5V19.25H26.25V21H22.75V15.75H28C28 14.7835 27.2165 14 26.25 14H22.75C21.7835 14 21 14.7835 21 15.75V21C21 21.9665 21.7835 22.75 22.75 22.75H26.25C27.2165 22.75 28 21.9665 28 21V19.25C28 18.2835 27.2165 17.5 26.25 17.5Z"></path>
                          <path d="M10.5 14H7C6.51673 14 6.125 14.3917 6.125 14.875V22.75H7.875V20.125H10.5C11.4665 20.125 12.25 19.3415 12.25 18.375V15.75C12.25 14.7835 11.4665 14 10.5 14ZM10.5 18.375H7.875V15.75H10.5V18.375Z"></path>
                          <path d="M18.375 19.25L14.7 14.35C14.41 13.9635 13.8616 13.8851 13.475 14.175C13.2547 14.3403 13.125 14.5996 13.125 14.875V22.75H14.875V17.5L18.55 22.4C18.7153 22.6204 18.9746 22.75 19.25 22.75C19.3451 22.7508 19.4397 22.736 19.53 22.7063C19.8864 22.5859 20.126 22.2512 20.125 21.875V14H18.375V19.25Z"></path>
                        </svg>
                      </span>
                      <span className="info">
                        <a href={DLSilverLabelPNG}>Silver Label (PNG)</a>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a>
                      <span className="icon">
                        <svg
                          viewBox="0 0 28 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M1.75 25.375V2.62501C1.75 2.14174 2.14173 1.75001 2.625 1.75001H15.75V5.25001C15.75 6.21651 16.5335 7.00001 17.5 7.00001H21V9.62501H22.75V6.12501C22.7514 5.89243 22.66 5.66887 22.4962 5.50376L17.2462 0.253764C17.0811 0.0899749 16.8576 -0.00129854 16.625 1.39617e-05H2.625C1.17523 1.39617e-05 0 1.1753 0 2.62501V25.375C0 26.8248 1.17523 28 2.625 28H8.75V26.25H2.625C2.14178 26.25 1.75 25.8583 1.75 25.375Z"></path>
                          <path d="M26.25 17.5H24.5V19.25H26.25V21H22.75V15.75H28C28 14.7835 27.2165 14 26.25 14H22.75C21.7835 14 21 14.7835 21 15.75V21C21 21.9665 21.7835 22.75 22.75 22.75H26.25C27.2165 22.75 28 21.9665 28 21V19.25C28 18.2835 27.2165 17.5 26.25 17.5Z"></path>
                          <path d="M18.375 14H14.875C14.3917 14 14 14.3917 14 14.875V22.75H15.75V20.125H18.375C19.3415 20.125 20.125 19.3415 20.125 18.375V15.75C20.125 14.7835 19.3415 14 18.375 14ZM18.375 18.375H15.75V15.75H18.375V18.375Z"></path>
                          <path d="M11.375 21H7.875V22.75H11.375C12.3415 22.75 13.125 21.9665 13.125 21V14H11.375V21Z"></path>
                        </svg>
                      </span>
                      <span className="info">
                        <a href={DLSilverLabelJPG}>Silver Label (JPG)</a>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="label-download__block--box label-download__gold">
                <div className="image">
                  <img
                    src={GoldLabel}
                    className="mw-100"
                    alt="Cyber Trust Austria Gold Label"
                    title="Cyber Trust Austria Gold Label"
                  />
                </div>
                <ul>
                  <li>
                    <a>
                      <span className="icon">
                        <svg
                          viewBox="0 0 28 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M1.75 25.3735V2.62355C1.75 2.14028 2.14173 1.74855 2.625 1.74855H15.75V5.24855C15.75 6.21504 16.5335 6.99855 17.5 6.99855H21V9.62355H22.75V6.12355C22.7514 5.89096 22.66 5.6674 22.4962 5.5023L17.2462 0.252299C17.0811 0.08851 16.8576 -0.00276338 16.625 -0.00145088H2.625C1.17523 -0.00145088 0 1.17384 0 2.62355V25.3735C0 26.8233 1.17523 27.9985 2.625 27.9985H8.75V26.2485H2.625C2.14178 26.2485 1.75 25.8568 1.75 25.3735Z"></path>
                          <path d="M26.25 17.5H24.5V19.25H26.25V21H22.75V15.75H28C28 14.7835 27.2165 14 26.25 14H22.75C21.7835 14 21 14.7835 21 15.75V21C21 21.9665 21.7835 22.75 22.75 22.75H26.25C27.2165 22.75 28 21.9665 28 21V19.25C28 18.2835 27.2165 17.5 26.25 17.5Z"></path>
                          <path d="M10.5 14H7C6.51673 14 6.125 14.3917 6.125 14.875V22.75H7.875V20.125H10.5C11.4665 20.125 12.25 19.3415 12.25 18.375V15.75C12.25 14.7835 11.4665 14 10.5 14ZM10.5 18.375H7.875V15.75H10.5V18.375Z"></path>
                          <path d="M18.375 19.25L14.7 14.35C14.41 13.9635 13.8616 13.8851 13.475 14.175C13.2547 14.3403 13.125 14.5996 13.125 14.875V22.75H14.875V17.5L18.55 22.4C18.7153 22.6204 18.9746 22.75 19.25 22.75C19.3451 22.7508 19.4397 22.736 19.53 22.7063C19.8864 22.5859 20.126 22.2512 20.125 21.875V14H18.375V19.25Z"></path>
                        </svg>
                      </span>
                      <span className="info">
                        <a href={DLGoldLabelPNG}>Gold Label (PNG)</a>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a>
                      <span className="icon">
                        <svg
                          viewBox="0 0 28 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M1.75 25.375V2.62501C1.75 2.14174 2.14173 1.75001 2.625 1.75001H15.75V5.25001C15.75 6.21651 16.5335 7.00001 17.5 7.00001H21V9.62501H22.75V6.12501C22.7514 5.89243 22.66 5.66887 22.4962 5.50376L17.2462 0.253764C17.0811 0.0899749 16.8576 -0.00129854 16.625 1.39617e-05H2.625C1.17523 1.39617e-05 0 1.1753 0 2.62501V25.375C0 26.8248 1.17523 28 2.625 28H8.75V26.25H2.625C2.14178 26.25 1.75 25.8583 1.75 25.375Z"></path>
                          <path d="M26.25 17.5H24.5V19.25H26.25V21H22.75V15.75H28C28 14.7835 27.2165 14 26.25 14H22.75C21.7835 14 21 14.7835 21 15.75V21C21 21.9665 21.7835 22.75 22.75 22.75H26.25C27.2165 22.75 28 21.9665 28 21V19.25C28 18.2835 27.2165 17.5 26.25 17.5Z"></path>
                          <path d="M18.375 14H14.875C14.3917 14 14 14.3917 14 14.875V22.75H15.75V20.125H18.375C19.3415 20.125 20.125 19.3415 20.125 18.375V15.75C20.125 14.7835 19.3415 14 18.375 14ZM18.375 18.375H15.75V15.75H18.375V18.375Z"></path>
                          <path d="M11.375 21H7.875V22.75H11.375C12.3415 22.75 13.125 21.9665 13.125 21V14H11.375V21Z"></path>
                        </svg>
                      </span>
                      <span className="info">
                        <a href={DLGoldLabelJPG}>Gold Label (JPG)</a>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default LabelDownload
